@font-face {
  font-family: 'Grotesk';
  src: local('Grotesk'), url(./assets/grotesk_font.woff2) format('woff2');
}

@media screen and (max-width: 750px) {
  iframe {
    width: 300px !important;
    height: 225px !important;
  }

  .hide-750 {
    display: none !important;
  }

  .show-750 {
    display: block !important;
  }

  .media-header {
    width: 300px !important;
    height: auto !important;
  }
}

body {
  margin: 0;
  position: relative;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:active, a:visited, a:focus {
  text-decoration:none;
}

#root {
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

.blue-button {
  background-color: #034e5b !important;
}

.blue-text {
  color: #034e5b !important;
}

.blue-button:hover {
  background-color: #9cd70a !important;
}

.green-button {
  background-color: #9cd70a !important;
}

.blue-button-white {
  background-color: #034e5b !important;
}

.blue-button-white:hover {
  background-color: rgb(183, 183, 183) !important;
  color: white !important;
}

#flowchart {
  max-width: 67%;
}

.media-header-text {
  font-size: clamp(10px, 5vw, 50px);
}

#glow-container {
  -webkit-transition: text-shadow 1s linear;
  -moz-transition: text-shadow 1s linear;
  -ms-transition: text-shadow 1s linear;
  -o-transition: text-shadow 1s linear;
  transition: text-shadow 1s linear;
}
#glow-container.glow {
  text-shadow: 0 0 5px #9cd70a;
}
#glow-container.glow1 {
  text-shadow: 0 0 10px lightblue;
}

.gif_player img {
  max-width: 80%;
  margin: auto;
  text-align: center;
}

.gif_player {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-other {
  background: none;
  border: none;
  border-bottom: 2px solid white;
  margin-left: 10px;
  height: 40px;
  font-size: 2.125rem;
  color: white;
  font-family: Grotesk;
  width: 200px;
}

.company-other:focus {
  border: none !important;
  outline: none;
  border-bottom: 2px solid white !important;
}

.tooltip {
  max-width: 300px !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.social-share-icons {
  position: fixed;
  width: calc((100vw - clamp(725px, 60vw, 1100px)) / 2);
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.how-to-button {
  z-index: 15;
  position: fixed;
  width: calc((100vw - clamp(725px, 60vw, 1100px)) / 2);
  top: 50%;
  transform: translateY(-50%);
  font-size: clamp(.9rem, 1vw, 1.25rem);
}

.pointer {
  cursor: pointer;
}

.modal-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}

.centered {
 position: absolute;
 left: 50%;
 top: 47%;
 width: 100%;
 transform: translate(-50%, 0);
 text-align: center;
}

.a {
  color: black;
  text-decoration: underline;
}

.full-green {
  width: 100%;
  background: linear-gradient(to right bottom, #bad409, #bad409);
  position: relative;
}

.width2 {
  width: 80% !important
}

.text-grey {
  color:rgb(155, 155, 155)
}

.full-grey {
  background: #f6f6f6;
}

.grey-h1-button:hover {
  cursor: pointer;
  color: #9cd70a !important;
}

.green-hover:hover {
  color: #9cd70a !important;
}

.blue-hover:hover {
  color: #034e5b !important;
}

.poll-button {
  color: #034e5b !important;
}

.poll-button:hover {
  cursor: pointer;
}

.white-hover:hover {
  cursor: pointer;
  color: white !important;
}

.header-container {
  margin: auto;
  padding-top: 20px;
  max-width: clamp(725px, 60vw, 1250px);
  width: auto;
  overflow: auto;
}

.left-padding {
  padding-left: 13px;
}

.text-button {
  color: #034e5b;
  text-decoration: underline;
  background: none;
  border: none;
  font-family: Grotesk;
  font-size: 15px;
  letter-spacing: 1px;
}

/* .home-about {
  margin-right: 70px;
} */

.container-home {
  margin: auto;
  max-width: clamp(725px, 60vw, 1100px);
  overflow: auto;
  padding: 20px;
  position: relative;
}

.container {
  margin: auto;
  max-width: clamp(725px, 60vw, 1100px);
  overflow: auto;
  padding: 20px;
  position: relative;
}

.container2 {
  margin: auto;
  max-width: clamp(725px, 50vw, 900px);
  overflow: auto;
  padding: 20px;
}

.header-menu {
  display: none;
}

.logo-size {
  width: clamp(75px, 9vw, 130px);
  height: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}


/* @media screen and (min-width: 1400px) {
  .tab-border {
    width: 190px !important;
    z-index: -1;
  }
} */

@media screen and (max-width: 1200px) {
  /*.header-logo {*/
  /*  margin-left: clamp(10px, 1vw, 1000px) !important;*/
  /*}*/
  /* .container {
    margin: auto;
    max-width: 800px;
    overflow: auto;
    padding: 20px;
  } */
/*
  .home-about {
    margin-right: 0px;
  } */

  /* .header-container {
    margin: auto;
    max-width: 1200px;
    padding-top: 20px;
    overflow: auto;
  } */
}
.green-left-background {
  height: 100%;
  width: calc((100vw - clamp(725px, 60vw, 1100px)) / 2);
  position: absolute;
  background-color: #9cd70a;
}

.green-home-left-background {
  height: 100%;
  width: calc((100vw - clamp(725px, 60vw, 1100px)) / 2);
  position: absolute;
  background-color: #9cd70a;
}

.green-home-gradient-background {
  height: 100%;
  width: 100%;
  margin-left: calc((100vw - clamp(725px, 60vw, 1100px)) / 2);
  position: absolute;
  background: linear-gradient(to right, #9cd70a, #bad409);
}

.how-to-button-home {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.react-swipeable-view-container div {
  display: flex;
}

@media screen and (max-width: 1100px) {
  .header-menu {
    display: flex;
    margin-right: clamp(10px, 6vw, 1000px) !important;
    align-items: center;
  }
  .header-tabs {
    display: none !important;
  }
  .login-button {
    display: none !important;
  }
  .header-logo {
    margin-left: clamp(10px, 6vw, 1000px) !important;
  }
  .green-left-background {
    display: none !important;
  }
  .green-left-background-container {
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .social-share-icons {
    display: none;
  }
  .how-to-button {
    display: none;
  }
  .how-to-button-home {
    display: flex !important;
  }
  #flowchart {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1000px) {
  .social-share-icons-mobile {
    display: none !important
  }
}

@media screen and (max-width: 750px) {
  #people-img{
    width: auto !important;
    height: 110px !important;
  }

  .home-about-holder{
    flex-direction:column !important;
  }
  .home-about{
    margin-top: 1rem;
    margin-left: 0 !important;
  }
  .introduction-holder{
    flex-direction: column-reverse !important;
    gap:1rem;
  }
  .mobile-flex-column{
    flex-direction: column !important;
    gap:1rem;
  }
  .mobile-flex-column-polls{
    flex-direction: column !important;
    gap:0 !important;
  }
  .mobile-margin-top{
    margin-top: 110px
  }
  .mobile-center{
    display:flex;
    flex-direction: column !important;
    align-items: center;
  }
  .mobile-form{
    padding: 25px 30px !important;
  }
  .mobile-none{
    display: none !important;
  }
  .mobile-display{
    display: flex !important;
    margin-bottom: 3rem !important;
  }
  .trending-polls{
    padding-top:20px !important;
  }
  .voted-worst{
    flex-direction: column !important;
    gap :1rem;
  }
  .mobile-featured-cat{
    padding-top:20px !important;
    padding-bottom:20px !important;
  }
}
